*{
/*  
 *
  la voz colores
 *  --primary: #FF406F;
 *
  --primaryDark: #D13E62;
  --secondary: #FFFFFF;
  --secondaryDark: #000000;
  */
  --primary: #FF831F;
  --primaryDark: #D96D07;
  --secondary: #FFFFFF;
  --secondaryDark: #0700FF;
}

.app-container{
  background: var(--primary);
  padding: 0 2rem;
  color: var(--secondary);
  width: 100%;
}


.chat{
  height: 100vh;
  width: 100%;
  padding: 1.5rem 0;
}

.messages{
  background: var(--secondary);
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
}

.message{
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.message:last-child{
  margin-bottom: 2rem;
}

.msg-content{
  max-width: 90%;
  display: inline-block;
  padding: 0.3rem 0.5rem;
  background: var(--primary);
  color: var(--secondary);
}

@media screen and (min-width: 768px){
  .msg-content{
    max-width: 50%;
  }
}

.msg-content .username{
  width: 100%;
  text-align: left;
  color: var(--secondary);
  font-weight: bold;
  font-size: 1.2rem;
}

.msg-content .text{
  width: 100%;
  margin-bottom: 0;
  word-wrap: break-word;
}

.info{
  padding: 3rem 0;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.title{
  font-weight: bold;
}

.image{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.names h3{
  color: var(--secondaryDark);
}


